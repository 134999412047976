.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-100) var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  gap: var(--spacing-24);
  background: var(--bg-color);
  color: var(--text-color);
  gap: var(--spacing-40);
  padding: var(--padding-container);
}

.container a {
  color: var(--text-color);
}

.title {
  padding: 0 !important;
}

.contentContainer{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-40);
}

.content{
  font: var(--font-p1-desktop-default);
}

.buttons{
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-40);
}

.p1, .p1 *{
  font: var(--font-p1-desktop-default) !important;
}

.p2, .p2 *{
  font: var(--font-p2-desktop-default) !important;
}

.p3, .p3 *{
  font: var(--font-p3-desktop-default) !important;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-64) var(--spacing-24);
    gap: var(--spacing-24);
  }
  .buttons{
    gap: var(--spacing-24);
  }
  .contentContainer * {
    font: var(--font-p1-mobile-default);
  }
}

@media (max-width: 768px) {
  .content{
    font: var(--font-p1-mobile-default);
  }
  .p1, .p1 *{
    font: var(--font-p1-mobile-default) !important;
  }
  
  .p2, .p2 *{
    font: var(--font-p2-mobile-default) !important;
  }
  
  .p3, .p3 *{
    font: var(--font-p3-mobile-default) !important;
  }
}

@media (max-width: 480px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
}